import { useEffect } from "react";

const HomeScreen = () => {
  useEffect(() => {
    const url = window.location.href;
    if (url.toLowerCase().includes('lincoln')) {
      window.location.href = 'https://www.lincoln.com/';
    } else {
      window.location.href = 'https://www.ford.com/';
    }
  }, []);

  return (
    <h1>Redirecting...</h1>
  );
};

export default HomeScreen;
