import { API_URL } from '../utils/api_url';

// const baseURL = 'https://api.dtg.com/';
const baseURL = API_URL;

const doFetch = async (url: RequestInfo, options: RequestInit, skipResults: boolean = false):Promise<DTGFetchResult> => {
  // console.log('[dtg]', 'doFetch', url);

  // add standard header stuff
  options.headers = {
    'Content-Type': 'application/json',
    'Authorization': 'ec2e7aeba3354a29b1eaf31e8772766a',
    // 'latitude-app-version': `web:${stores.ui.appVersion}`,
    ...options.headers,
  };

  const response = await window.fetch(url, options);
  let result:object;
  try {
    result = await response.json();
  } catch (e:unknown) {
    console.error(`[doFetch]`, e);
    result = {};
  }

  // console.log(`response ${url}`, {
  //   status: response.status,
  //   statusText: response.statusText,
  //   data: result,
  //   headers: response.headers,
  // });
  return {response, result};
}

class ShowTrackerService implements IService {
  init = async () => {
    console.log(`[ShowTrackerAPI] init`);
  }

  getEvent = async (hashed_show_id:string, url:string):Promise<any | unknown | undefined> => {
    console.log(`[ShowTrackerAPI] getEvent`);

    try {

      const requestOptions: RequestInit = {
        method: 'POST',
        body: JSON.stringify({ hashed_show_id, wheelstand: url }),
      };

      const ret:any = await doFetch(`${baseURL}v1/show/fetch`, requestOptions);

      if (ret.response.status === 404) {
        console.error('getEvent', 'Event not found');
        return;
      }

      return ret;
    } catch (e) {
      // handle error
      console.error('getEvent', e);
      console.error(`[ShowTrackerAPI] getEvent`, e);
      return e;
    }
  }
}

export default new ShowTrackerService();
