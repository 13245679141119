import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useServices } from "src/services";
import { createComponent } from '@lit-labs/react';

import FMCPopoverClass from '@ford/ford-design-system/src/web-components/fmc-popover/src/FMCPopover';
import '@ford/ford-design-system/src/web-components/fmc-popover/fmc-popover';
import FMCOverflowMenuClass from '@ford/ford-design-system/src/web-components/fmc-overflow-menu/src/FMCOverflowMenu';
import FMCOverflowMenuItemClass from '@ford/ford-design-system/src/web-components/fmc-overflow-menu/src/FMCOverflowMenuItem';
import '@ford/ford-design-system/src/web-components/fmc-overflow-menu/fmc-overflow-menu';
import '@ford/ford-design-system/src/web-components/fmc-overflow-menu/fmc-overflow-menu-item';

// @ts-ignore
import PrivacyBanner from 'react-ford-ccpa-banner';

import FordLogo from "../assets/images/ford_logo_bluecircle.png";
import LincolnLogo from "../assets/images/Master_Lincoln_100th_logo_Final01232022_Master_Lincoln100_Short_Blk.png";

import { allVehicles } from "src/common/vehicles";
import Hashids from 'hashids';
// create a mapping of DOM to Synthetic Events
// this is an optional argument but be sure to include it if you need to handle events
const fmcPopoverMappings = {
  onOpen: 'fmc-popover-open',
  onClose: 'fmc-popover-close'
};

const FMCPopover = createComponent(React, 'fmc-popover', FMCPopoverClass, fmcPopoverMappings);
const FMCOverflowMenu = createComponent(React, 'fmc-overflow-menu', FMCOverflowMenuClass);
const FMCOverflowMenuItem = createComponent(React, 'fmc-overflow-menu-item', FMCOverflowMenuItemClass);

const hasher = new Hashids("stupid scammers");

const titleCase = (string) => {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
};

// STEP 2: rename the component
const VehicleScreen = () => {
  const { vehicle_path, hashed_show_id } = useParams();

  const thisVehicle = allVehicles[vehicle_path];
  if (!thisVehicle) {
    const url = window.location.href;
    if (url.toLowerCase().includes('lincoln')) {
      window.location.href = 'https://www.lincoln.com/';
    } else {
      window.location.href = 'https://www.ford.com/';
    }
  }

  const ENV = process.env.REACT_APP_ENV

  const { showtracker } = useServices();

  const [isPopoverOpened, setIsPopoverOpened] = useState(false);
  const [surveyURL, setSurveyURL] = useState();
  const [showName, setShowName] = useState();

  const [surveyButton, setSurveyButton] = useState('Request More Information');

  const subNavClasses = {};
  if (thisVehicle.brand === 'ford') {
    subNavClasses["data-dark"] = true;
  }

  const ctaButtonClasses = {};
  if (thisVehicle.darkBackground) {
    ctaButtonClasses["data-dark"] = true;
  }

  const handleSelected = () => {
    setIsPopoverOpened(false);
  };

  useEffect(() => {
    const root = document.documentElement;
    root.setAttribute('data-brand', thisVehicle.brand);

    // set the page title
    document.title = thisVehicle.name;
    // set page description
    document.querySelector('meta[name="description"]').setAttribute("content", thisVehicle.tag);

    // set favicon
    var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = `/favicon-${thisVehicle.brand}.ico`;
    document.getElementsByTagName('head')[0].appendChild(link);

    console.log('hashed_show_id', hashed_show_id);


    showtracker.getEvent(hashed_show_id, window.location.href).then(ret => {
      const { show_name, show_id } = ret.result;
      console.log('show', show_name, show_id);
      if (show_id) {
        setShowName(show_name);
        setSurveyButton('Schedule Test Drive');
        // setSurveyButton('Request More Information');
        if (ENV !== "staging" && ENV !== "production") {
          setSurveyURL(`http://localhost:8080/#/survey/${hashed_show_id}/${thisVehicle.brand === 'ford' ? '1000897' : '1000896'}/NOWHASH/?vehicleID=${thisVehicle.id}`);
        } else if (ENV === "staging") {
          setSurveyURL(`https://staging.microsite.fordshowtracker.com/#/survey/${hashed_show_id}/${thisVehicle.brand === 'ford' ? '1000897' : '1000896'}/NOWHASH/?vehicleID=${thisVehicle.id}`);
        } else {
          setSurveyURL(`https://microsite.fordshowtracker.com/#/survey/${hashed_show_id}/${thisVehicle.brand === 'ford' ? '1000897' : '1000896'}/NOWHASH/?vehicleID=${thisVehicle.id}`);
        }
      }
    });
  }, [ENV, hashed_show_id, showtracker, thisVehicle]);

  const surveyLinkHandler = () => {
    window.gtag('event', 'cta_click', {
      'event_category' : thisVehicle.name,
      'event_label' : 'survey',
      'vehicle_name' : thisVehicle.name,
      'show_name' : showName,
      'cta_button' : surveyButton,
    });

    const nowHash = hasher.encode(new Date().getTime());

    window.location.href = `${surveyURL.replace('NOWHASH',nowHash)}&redirect=${encodeURIComponent(window.location.href)}`;
  };

  const buildPriceLinkHandler = () => {
    window.gtag('event', 'cta_click', {
      'event_category' : thisVehicle.name,
      'event_label' : 'build_price',
      'vehicle_name' : thisVehicle.name,
      'show_name' : showName,
      'cta_button' : 'Build & Price',
    });
    window.open(thisVehicle.buildPriceLink, '_blank');
    handleSelected();
  };

  const homepageLinkHandler = () => {
    window.gtag('event', 'cta_click', {
      'event_category' : thisVehicle.name,
      'event_label' : 'discover_more',
      'vehicle_name' : thisVehicle.name,
      'show_name' : showName,
      'cta_button' : 'Discover More',
    });
    window.open(thisVehicle.homepageLink, '_blank');
    handleSelected();
  };

  const merchandiseLinkHandler = () => {
    window.gtag('event', 'cta_click', {
      'event_category' : thisVehicle.name,
      'event_label' : 'merchandise',
      'vehicle_name' : thisVehicle.name,
      'show_name' : showName,
      'cta_button' : `${titleCase(thisVehicle.brand)} Merchandise`,
    });
    window.open(thisVehicle.merchandiseLink, '_blank');
    handleSelected();
  };

  const accessoriesLinkHandler = () => {
    window.gtag('event', 'cta_click', {
      'event_category' : thisVehicle.name,
      'event_label' : 'accessories',
      'vehicle_name' : thisVehicle.name,
      'show_name' : showName,
      'cta_button' : `${titleCase(thisVehicle.brand)} Accessories`,
    });
    window.open(thisVehicle.accessoriesLink, '_blank');
    handleSelected();
  };


  return (
    <div className="fds-app js-fds">
      {
        thisVehicle.brand === 'ford' ? 
        <img src={FordLogo} alt="Ford Logo" className="fds-brand-global-logo" />
        :
        <img src={LincolnLogo} alt="Lincoln 100 Years" className="fds-brand-global-logo" />
      }
    <div className="subNav">
      <div className="mobileNav">
      <FMCPopover
        overflowMenu={true}
        opened={isPopoverOpened}
        onOpened={() => setIsPopoverOpened(true)}
        onClose={() => setIsPopoverOpened(false)}
        placement="bottom"
      >
        <button slot="trigger" className="fmc-text-button" {...subNavClasses}>Next Steps<span className="fds-icon fds-font--ford-icons__ellipsis fds-icon--offset-right"></span></button>
        <section slot="body">
          <FMCOverflowMenu>
            {
              thisVehicle.buildPriceLink && surveyURL &&
              <FMCOverflowMenuItem label="Build & Price" className="subNavMenuItem" onClick={buildPriceLinkHandler} />
            }
            {
              (thisVehicle.buildPriceLink || surveyURL) &&
              <FMCOverflowMenuItem label="Discover More" className="subNavMenuItem" onClick={homepageLinkHandler} />
            }
            {
              thisVehicle.accessoriesLink &&
              <FMCOverflowMenuItem label={`${titleCase(thisVehicle.brand)} Accessories`} className="subNavMenuItem" onClick={accessoriesLinkHandler} />
            }
            {
              thisVehicle.merchandiseLink &&
              <FMCOverflowMenuItem label={`${titleCase(thisVehicle.brand)} Merchandise`} className="subNavMenuItem" onClick={merchandiseLinkHandler} />
            }
          </FMCOverflowMenu>
        </section>
      </FMCPopover>
      </div>
      <div className="desktopNav">
      {
        thisVehicle.merchandiseLink &&
        <button
          className="fmc-text-button"
          onClick={merchandiseLinkHandler}
          {...subNavClasses}
        >
          {`${titleCase(thisVehicle.brand)} Merchandise`}
        </button>
      }
      {
        thisVehicle.accessoriesLink &&
        <button
          className="fmc-text-button"
          onClick={accessoriesLinkHandler}
          {...subNavClasses}
        >
          {`${titleCase(thisVehicle.brand)} Accessories`}
        </button>
      }
      {
        (thisVehicle.buildPriceLink || surveyURL) &&
        <button
          className="fmc-text-button"
          onClick={homepageLinkHandler}
          {...subNavClasses}
        >
        Discover More
      </button>
      }
      {
        thisVehicle.buildPriceLink && surveyURL &&
        <button
          className="fmc-text-button"
          onClick={buildPriceLinkHandler}
          {...subNavClasses}
        >
          Build & Price
        </button>
      }
      </div>
    </div>
    <div
      className="fmc-billboard fmc-billboard--align-start fds-color__bg--tertiary"
    >
      <img className="fmc-billboard__image fds-hide--xs fds-hide--sm" src={require(`../assets/images/${thisVehicle.desktopImage}`)} alt={thisVehicle.name} />
      <img className="fmc-billboard__image fds-hide--md fds-hide--lg fds-hide--xl" src={require(`../assets/images/${thisVehicle.mobileImage}`)} alt={thisVehicle.name} />
      {/* <div className="fmc-billboard__scrim fmc-billboard--scrim-top fmc-billboard--scrim-dark"></div> */}
      {/* <div className="fmc-billboard__content fds-color__text--gray1 fgx-brand-global-container-pad"> */}
      <div className="fmc-billboard__content">
        <h1 className="vehicleName fmc-type--heading3">{thisVehicle.name}</h1>
        <div className="ctaButtons">
        {
          surveyURL &&
          <button
            className="fmc-button"
            onClick={surveyLinkHandler}
            {...ctaButtonClasses}
          >
            {surveyButton}
          </button>
        }
        { thisVehicle.buildPriceLink && !surveyURL &&
          <button
            className="fmc-button"
            onClick={buildPriceLinkHandler}
            {...ctaButtonClasses}
          >
            Build & Price
          </button>
        }
        {
          !thisVehicle.buildPriceLink && !surveyURL &&
          <button
            className="fmc-button"
            onClick={homepageLinkHandler}
            {...ctaButtonClasses}
          >
            Discover More
          </button>
        }
        </div>
      </div>
    </div>
    <PrivacyBanner
        showPrivacy={true}
        team={thisVehicle.brand}
        imgStyle={thisVehicle.brand === 'ford' ? {paddingLeft: '30px'} : null}
        // otherLinks={[
        //     { text: "CONTACT US", url: "/contact" },
        //     { text: "ELIGIBILITY TERMS AND CONDITIONS", url: "/terms-eligibility" },
        //     { text: "EVENT TERMS AND CONDITIONS", url: "/terms-event" },
        //     { text: "ORGANIZATION GUIDELINES", url: "/organization-guidelines" },
        // ]}
      />
    </div>
  );
};

export default VehicleScreen;
