export const allVehicles = {
    "Corsair": {
        id: 1029,
        brand: 'lincoln',
        name: 'Lincoln Corsair',
        tag: 'ACCOMMODATING ADAPTABILITY',
        desktopImage: 'Lincoln_2023_Corsair_Desktop.jpg',
        mobileImage: 'Lincoln_2023_Corsair_Mobile.jpg',
        homepageLink: 'https://www.lincoln.com/luxury-suvs/corsair/2023/',
        buildPriceLink: 'https://shop.lincoln.com/build/corsair/',
        merchandiseLink: 'https://merchandise.lincoln.com/',
        darkBackground: true,
    },
    "Bronco": {
        id: 202,
        brand: 'ford',
        name: 'Ford Bronco®',
        tag: 'This One Is For The Builders',
        desktopImage: 'Ford_2022_Bronco_Desktop.jpg',
        mobileImage: 'Ford_2022_Bronco_Mobile.jpg',
        homepageLink: 'https://www.ford.com/suvs/bronco/',
        buildPriceLink: 'https://shop.ford.com/configure/bronco/',
        merchandiseLink: 'https://merchandise.ford.com/collections/bronco',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-bronco-2022',
        darkBackground: false,
    },
    "BroncoRaptor": {
        id: 202,
        brand: 'ford',
        name: 'Ford Bronco® Raptor®',
        tag: 'The Most Powerful Street-Legal Bronco Ever',
        desktopImage: 'Ford_2023_Bronco_Raptor_Desktop.jpg',
        mobileImage: 'Ford_2023_Bronco_Raptor_Mobile.jpg',
        homepageLink: 'https://www.ford.com/suvs/bronco/models/bronco-raptor/',
        buildPriceLink: 'https://shop.ford.com/configure/bronco/',
        merchandiseLink: 'https://merchandise.ford.com/collections/bronco',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-bronco-2022',
        darkBackground: false,
    },
    "BroncoSport": {
        id: 1009,
        brand: 'ford',
        name: 'Ford Bronco® Sport',
        tag: 'The Journey is as Important as the Destination',
        desktopImage: 'Ford_2023_BroncoSport_Desktop.jpg',
        mobileImage: 'Ford_2023_BroncoSport_Mobile.jpg',
        homepageLink: 'https://www.ford.com/suvs/bronco-sport/',
        buildPriceLink: 'https://shop.ford.com/configure/broncosport/',
        merchandiseLink: 'https://merchandise.ford.com/collections/bronco',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-bronco-sport-2022',
        darkBackground: true,
    },
    "EcoSport": {
        id: 194,
        brand: 'ford',
        name: 'Ford EcoSport',
        tag: 'The Feel Good, Do Everything EcoSport',
        desktopImage: 'Ford_2022_EcoSport_Desktop.jpg',
        mobileImage: 'Ford_2022_EcoSport_Mobile.jpg',
        homepageLink: 'https://www.ford.com/suvs/ecosport/',
        buildPriceLink: 'https://shop.ford.com/configure/ecosport/',
        merchandiseLink: 'https://merchandise.ford.com/',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-ecosport-2022',
        darkBackground: true,
    },
    "Edge": {
        id: 223,
        brand: 'ford',
        name: 'Ford Edge',
        tag: 'The New Era of Edge',
        desktopImage: 'Ford_2022_Edge_Desktop.jpg',
        mobileImage: 'Ford_2022_Edge_Mobile.jpg',
        homepageLink: 'https://www.ford.com/suvs/edge/',
        buildPriceLink: 'https://shop.ford.com/configure/edge/',
        merchandiseLink: 'https://merchandise.ford.com/',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-edge-2022',
        darkBackground: true,
    },
    "Escape": {
        id: 172,
        brand: 'ford',
        name: 'Ford Escape',
        tag: 'Compact SUV. Full Size Capabilities.',
        desktopImage: 'Ford_2023_Escape_Desktop.jpg',
        mobileImage: 'Ford_2023_Escape_Mobile.jpg',
        homepageLink: 'https://www.ford.com/suvs/escape/',
        buildPriceLink: 'https://shop.ford.com/configure/escape/',
        merchandiseLink: 'https://merchandise.ford.com/',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-escape-2022',
        darkBackground: false,
    },
    "Expedition": {
        id: 173,
        brand: 'ford',
        name: 'Ford Expedition®',
        tag: 'Your Basecamp for Adventure',
        desktopImage: 'Ford_2023_Expedition_Desktop.jpg',
        mobileImage: 'Ford_2023_Expedition_Mobile.jpg',
        homepageLink: 'https://www.ford.com/suvs/expedition/',
        buildPriceLink: 'https://shop.ford.com/configure/expedition/',
        merchandiseLink: 'https://merchandise.ford.com/',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-expedition-2022',
        darkBackground: true,
    },
    "Explorer": {
        id: 174,
        brand: 'ford',
        name: 'Ford Explorer',
        tag: 'The Ultimate Exploration Vehicle',
        desktopImage: 'Ford_2022_Explorer_Desktop_v2.jpeg',
        mobileImage: 'Ford_2022_Explorer_Mobile_v2.jpeg',
        homepageLink: 'https://www.ford.com/suvs/explorer/',
        buildPriceLink: 'https://shop.ford.com/configure/explorer/',
        merchandiseLink: 'https://merchandise.ford.com/',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-explorer-2022',
        darkBackground: true,
    },
    "F150": {
        id: 175,
        brand: 'ford',
        name: 'Ford F-150®',
        tag: 'THE TRUCK OF AUTHORITY',
        desktopImage: 'Ford_2023_F-150_Desktop.jpg',
        mobileImage: 'Ford_2023_F-150_Mobile.jpg',
        homepageLink: 'https://www.ford.com/trucks/f150/',
        buildPriceLink: 'https://shop.ford.com/configure/f150/',
        merchandiseLink: 'https://merchandise.ford.com/collections/trucks',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-f-150-2022',
        darkBackground: true,
    },
    "Lightning": {
        id: 1013,
        brand: 'ford',
        name: 'Ford F-150® Lightning®',
        tag: 'Made For Play',
        desktopImage: 'Ford_2023_Lightning_Desktop.jpg',
        mobileImage: 'Ford_2023_Lightning_Mobile.jpg',
        homepageLink: 'https://www.ford.com/trucks/f150/f150-lightning',
        buildPriceLink: 'https://shop.ford.com/configure/f150-lightning',
        merchandiseLink: 'https://merchandise.ford.com/collections/lightning',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-f-150-lightning-2022',
        darkBackground: true,
    },
    "Maverick": {
        id: 1014,
        brand: 'ford',
        name: 'Ford Maverick®',
        tag: 'MAKE IT WITH MAVERICK',
        desktopImage: 'Ford_2023_Maverick_Desktop.jpg',
        mobileImage: 'Ford_2023_Maverick_Mobile.jpg',
        homepageLink: 'https://www.ford.com/trucks/maverick/',
        buildPriceLink: 'https://shop.ford.com/configure/maverick',
        merchandiseLink: 'https://merchandise.ford.com/collections/maverick',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-maverick-2022',
        darkBackground: true,
    },
    "Mustang": {
        id: 186,
        brand: 'ford',
        name: 'Ford Mustang®',
        tag: 'If you think you know what to expect, this is your wake-up call.',
        desktopImage: 'Ford_2024_Mustang_Desktop.jpg',
        mobileImage: 'Ford_2024_Mustang_Mobile.jpg',
        homepageLink: 'https://www.ford.com/cars/mustang/2024/',
        buildPriceLink: 'https://shop.ford.com/configure/mustang/',
        merchandiseLink: 'https://merchandise.ford.com/collections/mustang',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-mustang-2022',
        darkBackground: true,
    },
    "MachE": {
        id: 1030,
        brand: 'ford',
        name: 'Ford Mustang Mach-E®',
        tag: 'Beat the Rush Before You Feel It',
        desktopImage: 'Ford_2023_Mustang_Mach-E_Desktop_v2.jpeg',
        mobileImage: 'Ford_2023_Mustang_Mach-E_Mobile_v2.jpeg',
        homepageLink: 'https://www.ford.com/suvs/mach-e/',
        buildPriceLink: 'https://www.ford.com/buy/mach-e/build-and-price.html#/model',
        merchandiseLink: 'https://merchandise.ford.com/collections/mach-e',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-mustang-mach-e-2021',
        darkBackground: true,
    },
    "Ranger": {
        id: 198,
        brand: 'ford',
        name: 'Ford Ranger',
        tag: 'BOLDLY GO',
        desktopImage: 'Ford_2023_Ranger_Desktop.jpg',
        mobileImage: 'Ford_2023_Ranger_Mobile.jpg',
        homepageLink: 'https://www.ford.com/trucks/ranger/',
        buildPriceLink: 'https://shop.ford.com/configure/ranger',
        merchandiseLink: 'https://merchandise.ford.com/collections/ranger',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-ranger-2022',
        darkBackground: true,
    },
    "SuperDuty": {
        id: 1003,
        brand: 'ford',
        name: 'Ford Super Duty®',
        tag: 'This One Is For The Builders',
        desktopImage: 'Ford_2023_SuperDuty_Desktop.jpg',
        mobileImage: 'Ford_2023_SuperDuty_Mobile.jpg',
        homepageLink: 'https://www.ford.com/trucks/super-duty/2023/',
        buildPriceLink: 'https://shop.ford.com/build/superduty/',
        merchandiseLink: 'https://merchandise.ford.com/collections/super-duty',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-super-duty-2021',
        darkBackground: false,
    },
    "Transit": {
        id: 190,
        brand: 'ford',
        name: 'Ford Transit®',
        tag: 'LIFE\'S A JOURNEY. AND WE\'RE HERE FOR IT',
        desktopImage: 'Ford_2023_TransitVan_Desktop.jpg',
        mobileImage: 'Ford_2023_TransitVan_Mobile.jpg',
        homepageLink: 'https://www.ford.com/trucks/transit-passenger-van-wagon/',
        buildPriceLink: 'https://shop.ford.com/configure/transitvanwagon',
        merchandiseLink: 'https://merchandise.ford.com/',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-transit-2022',
        darkBackground: false,
    },
    "TransitConnect": {
        id: 191,
        brand: 'ford',
        name: 'Ford Transit Connect',
        tag: 'JUST LOAD IT UP AND GET GOING',
        desktopImage: 'Ford_2022_TransitConnectPassengerWagon_Desktop.jpg',
        mobileImage: 'Ford_2022_TransitConnectPassengerWagon_Mobile.jpg',
        homepageLink: 'https://www.ford.com/trucks/transit-connect-passenger-van-wagon/',
        buildPriceLink: 'https://shop.ford.com/build/transitconnectwagon',
        merchandiseLink: 'https://merchandise.ford.com/',
        accessoriesLink: 'https://accessories.ford.com/pages/ford-transit-connect-2022',
        darkBackground: true,
    },
};
