export const basePath = `microsite`;
export const getApiURL = (ENV = process.env.REACT_APP_ENV, path = basePath) => {
  let baseURL = "showtracker.latitudewebservices.com/";
  let protocol = "https://";


  if (ENV !== "staging" && ENV !== "production") {
    protocol = "http://";
    baseURL = "localhost:3000/";
  }
  if (ENV === "staging") {
    baseURL = "staging.showtracker.latitudewebservices.com/";
  }

  const domain = `${baseURL}${basePath}`;

  return `${protocol}${domain}/`;
};

export const API_URL = getApiURL();
