import "./App.scss";

import Home from "./pages/Home";
import VehicleScreen from "./pages/VehicleScreen";

import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {
  return (
    <div className="fds-app js-fds">
      <Router >
        <Routes>
          <Route path="/" exact element={<Home />}/>
          <Route
            path="/:vehicle_path"
            element={<VehicleScreen />}
          />
          <Route
            path="/:vehicle_path/:hashed_show_id"
            element={<VehicleScreen />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
